import React, { useEffect, useRef, useState } from 'react';
import { StreamPixelApplication } from 'streampixel';
import axios from 'axios';
import {  FaVolumeUp, FaVolumeMute, FaExpand, FaCompress } from 'react-icons/fa'; 

let PixelStreamingApp;
let PixelStreamingUiApp;

const App = () => {
  const videoRef = useRef(null);
  const hasStartedPlay = useRef(false);
  const audioRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false); 
  const [isMuted, setIsMuted] = useState(true); 
  const [keyStates, setKeyStates] = useState({ t: false, five: false });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stats, setStats] = useState(null);
  const [status, setStatus] = useState(null);
  const [delayTime, setDelayTime] = useState(20000);
  const [showBtn, setShowBtn] = useState(true);
  const [showMic, setShowMic] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showEnterPassword, setShowEnterPassword] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [project, setProject] = useState();

  const [uiBtn, setShowUiBtn] = useState(false);

  const [projectId, setProjectId] = useState(null); // New state to manage the actual project ID

  // Extract the last part of the URL
  const urlPart = window.location.href.split('/').pop();

  useEffect(() => {
    if (urlPart) {
      // Check if URL part contains 'share-', then fetch via share API
      if (urlPart.startsWith('share-')) {
        fetchShareProjectDetails(urlPart);
      } else {
        // If it's a projectId directly, set projectId and fetch details
        setProjectId(urlPart);
      }
    } else {
      setStatus('invalidurl');
    }
  }, [urlPart]);



  const getStats =()=> {
    PixelStreamingUiApp.statsPanel.show();
    var statsElement = PixelStreamingUiApp.statsPanel._statsResult;
 
  
    if (!statsElement) {
      return {}; // Return an empty object if no element is found
    }
  
    const statDivs = statsElement.querySelectorAll('div');
  
  
    if (!statDivs.length) {
      return {}; // Return empty object if no divs found
    }
  
    const stats = {};
  
    statDivs.forEach((div, index) => {
      const text = div.textContent.trim(); // Use textContent instead of innerText
  
      // Skip empty divs (check length and trim for any potential white spaces)
      if (text.length === 0) {
        return;
      }
  
  
      const [key, value] = text.split(':').map(item => item.trim());
  
      if (key && value) {
        stats[key] = value;
      } else {
      }
    });
  
    return stats;
  }
  
  // Function to fetch project details via shareId API
  const fetchShareProjectDetails = async (shareId) => {
    try {
      const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/shareId/${shareId}`);
      if (response.status === 200) {
        // Extract projectId from the response and set it
        const { _id: projectId } = response.data;
       console.log(response.data);
        console.log(projectId);
        setProjectId(projectId); // Set the actual project ID for further use
      }
    } catch (error) {
      console.error('Error fetching project details via shareId:', error);
      setStatus('error');
    }
  };

  // Once we have projectId (either from URL or after shareId fetch), fetch the project data
  useEffect(() => {
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  // Fetch project details using the final projectId
  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.streampixel.io/pixelStripeApi/projects/${projectId}`);
      if (response.status === 200) {
        const { passwordProtect, password, delay, showBtn, showMic, status: projectStatus } = response.data;
        setShowEnterPassword(passwordProtect);
        setProject(response.data);
        setDelayTime(delay);
        setShowBtn(showBtn);
        setShowMic(showMic);
        setStatus(projectStatus ? 'active' : 'inactive');
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
      setStatus('error');
    }
  };



  // Handle key presses
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key.toLowerCase() === 't') setKeyStates((prev) => ({ ...prev, t: true }));
      if (event.key === '5') setKeyStates((prev) => ({ ...prev, five: true }));
    };

    const handleKeyUp = (event) => {
      if (event.key.toLowerCase() === 't') setKeyStates((prev) => ({ ...prev, t: false }));
      if (event.key === '5') setKeyStates((prev) => ({ ...prev, five: false }));
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Monitor key states for specific actions
  useEffect(() => {
    if (keyStates.t && keyStates.five) {
     
      if (PixelStreamingUiApp) {
        const statsData = getStats();
        setStats(statsData);
        setIsModalVisible((prev) => !prev);
      }
    }
  }, [keyStates]);

  
  useEffect(() => {
    if (!hasStartedPlay.current && status === 'active' && !showEnterPassword) {
      hasStartedPlay.current = true;
      startPlay();
    }
  }, [status, showEnterPassword]);

  const startPlay = async () => {
    const { appStream, pixelStreaming } = StreamPixelApplication({
      AutoPlayVideo: true,
      StartVideoMuted: true,
      AutoConnect: true,
      useMic: project.showMic,
      appId: projectId,
      afktimeout:project.afktimeout,
      touchInput:project.touchInput,
      mouseInput:project.mouseInput,
      gamepadInput:project.gamepadInput,
      resolution:project.resolution,
      hoverMouse:project.hoverMouse,
      xrInput:project.xrInput,
      keyBoardInput:project.keyBoardInput,
    });

    PixelStreamingApp = pixelStreaming;
    PixelStreamingUiApp = appStream;

    PixelStreamingApp.addResponseEventListener('handle_responses', handleResponseApp);



    videoRef.current.append(appStream.rootElement);

    const videoElement = appStream && appStream.stream.videoElementParent.querySelector("video");
    if(videoElement){
      videoElement.muted = false;
      videoElement.focus();
      videoElement.autoplay = true;
      videoElement.tabIndex = 0;
     
    }



    appStream.onVideoInitialized = function() {
      window.parent.postMessage("loadingComplete", "*");
      setShowUiBtn(true);
    }

 

    if(project.showMic == true){
    
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          // Handle the audio stream (e.g., connect to WebRTC or WebAudio API)
          pixelStreaming.unmuteMicrophone(true);
        } catch (err) {
          console.error('Microphone access denied', err);
        }


     
    
    }
  
    addMessageListener();
  };




  const handleResponseApp = (response) => {
    console.log(response);
    if (response.includes('https')) {
      window.open(response);
    }
    window.parent.postMessage(response, '*');
  };

  const addMessageListener = () => {
    window.addEventListener('message', (event) => {
      if(PixelStreamingUiApp){
      const audioElement = PixelStreamingUiApp.stream._webRtcController.streamController.audioElement;
      
      const { message } = event.data;

      if (message === 'terminateSession') {
        handleTerminateSession();
      } 
       if (message === 'muteAudio') {
       
          audioElement.pause();
          audioElement.muted = !isMuted;
        
      }
      if(message === 'unMuteAudio'){

        audioElement.play();
        audioElement.muted = !isMuted;

      }
    
      handleSendCommand(event.data);
    }
    });
    
  };

  const handleTerminateSession = () => {
    PixelStreamingApp.disconnect();
    PixelStreamingUiApp?.stream.disconnect();
  };

  const handleSendCommand = (descriptor) => {
    PixelStreamingUiApp?.stream.emitUIInteraction(descriptor);
  };

  const handlePasswordSubmit = async () => {
    try {
      const res = await fetch('https://api.streampixel.io/pixelStripeApi/projects/passwordCheck', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: enteredPassword, projectId }),
      });

      if (res.status === 200) {
        setPasswordValid(true);
        setShowEnterPassword(false);
      } else {
        alert('Invalid password');
        setPasswordValid(false);
      }
    } catch (error) {
      console.error('Error validating password:', error);
      alert('Error validating password. Please try again.');
      setPasswordValid(false);
    }
  };

  const toggleSound = () => {
    if (PixelStreamingUiApp) {
        const audioElement = PixelStreamingUiApp.stream._webRtcController.streamController.audioElement;
        audioElement.play();
        audioElement.muted = !isMuted;
        setIsMuted(!isMuted);
     
/*
        const audioTracks = audioElement.srcObject?.getAudioTracks(); // Get audio tracks
        if (audioTracks && audioTracks.length > 0) {

          console.log(audioTracks[0])
            audioTracks[0].enabled = !audioTracks[0].enabled; // Toggle mute/unmute the first audio track
            setIsMuted(!audioTracks[0].enabled); // Update the state after toggling
            console.log(`Audio track ${audioTracks[0].enabled ? 'unmuted' : 'muted'}.`);
        } else {
       
            console.warn("No audio tracks found in the audio stream.");
        }
      */      
     }
        
  }   

  const toggleFullscreen = () => {
    const doc = document.documentElement;
  
    if (!document.fullscreenElement) {
      // Request fullscreen
      if (doc.requestFullscreen) {
        doc.requestFullscreen();
      } else if (doc.mozRequestFullScreen) { // Firefox
        doc.mozRequestFullScreen();
      } else if (doc.webkitRequestFullscreen) { // Safari
        doc.webkitRequestFullscreen();
      } else if (doc.msRequestFullscreen) { // IE/Edge
        doc.msRequestFullscreen();
      }
  
      setIsFullscreen(true);
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
  
      setIsFullscreen(false);
    }
  };

  const renderContent = () => {



    if (status === 'inactive') {
      return <p>Not Allowed</p>;
    }
    if (status === 'notfound' || status === 'invalidurl') {
      return <p>Not Valid URL</p>;
    }
    return (
    <div className='containMain'>
   
    <div id="videoElement" ref={videoRef}>

    
       {uiBtn == true ?(
        <div style={styles.controlPanel}>
        <>      
         {project && project.showBtnVolume &&(
        <button style={styles.button} onClick={toggleSound}>
          {isMuted ? <FaVolumeMute size={20} /> : <FaVolumeUp size={20} />}
        </button>
       )}
        {project && project.showBtn &&(
        <button style={styles.button} onClick={toggleFullscreen}>
          {isFullscreen ? <FaCompress size={20} /> : <FaExpand size={20} />}
        </button>
        )}
        </>
        </div>

       ):( null)} 
      
</div>

    </div>
    );
  };

  return (
    <>
      {!showEnterPassword || passwordValid ? renderContent() : (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <input
            type="password"
            placeholder="Enter Password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            style={{ padding: '10px', fontSize: '16px', marginBottom: '10px' }}
          />
          <button onClick={handlePasswordSubmit} style={{ padding: '10px 20px', fontSize: '16px' }}>Submit</button>
        </div>
      )}

      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Statistics</h2>
            <button className="close-button" onClick={() => setIsModalVisible(false)}>X</button>
            <div className="stats-container">
              {stats && Object.entries(stats).map(([key, value]) => (
                <div key={key} className="stat-item">
                  <strong>{key}:</strong> {value}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;

const styles = {
  controlPanel: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    gap: '10px',
    zIndex:"200"
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
  },
};